import React from 'react';
import { makeStyles, Button } from '@material-ui/core';
import { useSharedContent } from 'src/hooks/customGraphqlHooks';


const useStyles = makeStyles(theme => ({
    button: {
        marginBottom: "1rem",
        textTransform: "none",
        display: "flex",
    },
}))

export enum ButtonType {
    SKIP = "SKIP",
    CONTINUE = "CONTINUE",
    NEXT = "NEXT",
    SUBMIT = "SUBMIT"
}

interface IProps {
    label?: string
    onClickHandler: (arg?: any) => any
    isDisabled?: boolean
    fullWidth?: boolean
    color?: "secondary" | "primary",
    type?: ButtonType
    endIcon?: React.ReactNode
    removePadding?: boolean
}

const StyledButton: React.FC<IProps> = ({ label,
    onClickHandler,
    isDisabled = false,
    fullWidth = false,
    color = "primary",
    type = ButtonType.CONTINUE,
    removePadding = false,
    endIcon
}) => {
    const classes = useStyles();
    const data = useSharedContent();

    function getButtonLabel() {
        switch (type) {
            case ButtonType.SKIP: return data?.skipButtonLabel;
            case ButtonType.CONTINUE: return data?.continueButtonLabel;
            case ButtonType.SUBMIT: return data?.submitButtonLabel;
        }
    }

    function addCustomStyling() {
        if (removePadding) return { padding: "10px 10px" }
        return {};
    }

    if (label) {
        return (
            <Button
                style={addCustomStyling()}
                variant="contained"
                color={color}
                className={classes.button}
                onClick={onClickHandler}
                fullWidth={fullWidth}
                disabled={isDisabled}
                endIcon={endIcon}
            >
                {label}
            </Button>
        )
    }
    return (
        <Button
            variant="contained"
            color={color}
            className={classes.button}
            onClick={onClickHandler}
            fullWidth={fullWidth}
            disabled={isDisabled}
        >
            {getButtonLabel()}
        </Button>
    )
}

export default StyledButton;