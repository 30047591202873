import { useStaticQuery, graphql } from "gatsby";
import { ContentfulSharedContentQuery } from "types/graphql-types";

export const useSharedContent = () => {
    const { contentfulSharedContent } = useStaticQuery<ContentfulSharedContentQuery>(
        graphql`
            query ContentfulSharedContent{
                contentfulSharedContent {
                    logo {
                        file{
                            url
                            contentType
                        }
                    }
                    pagination
                    continueButtonLabel
                    submitButtonLabel
                    skipButtonLabel
                    backgroundLinkLabel
                    privacyLinkLabel
                    aboutLinkLabel
                    implicitAssociationTutorialLeftHand {
                        id
                        contentful_id
                        file {
                            url
                            contentType
                        }
                        fixed {
                            ...GatsbyContentfulFixed
                        }
                        fluid {
                            ...GatsbyContentfulFluid
                        }
                    }
                    implicitAssociationTutorialRightHand {
                        id
                        contentful_id
                        file {
                            url
                            contentType
                        }
                        fixed {
                            ...GatsbyContentfulFixed
                        }
                        fluid {
                            ...GatsbyContentfulFluid
                        }
                    }
                }
            }
        `
    );

    return contentfulSharedContent;
}